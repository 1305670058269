import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import InternationaPatentImg from '../images/internation-patent.webp';
import IPProtectionImg from '../images/aws-openai-soc.jpg';
import '../styles/webinar-page.css';

const WebinarUnderstandingPatents = () => {
  const WEBINAR_TITLE = 'Understanding Patents: From Idea to Invention';
  const seo = {
    metaDesc: WEBINAR_TITLE
  }
  const WEBINAR_AI_MEETS = "https://webinarkit.com/webinar/registration/oneclick/67c81aa64c252cf908877412?date=jit_15";
  const childRef = useRef();
  const openPopup = () => {
    //
    childRef.current?.open(WEBINAR_AI_MEETS);
  }
  //
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <Layout activeMenu={'webinar'}>
      <SEO title={`Webinar ${WEBINAR_TITLE} - Inventiv.org`} canonical='/webinar-understanding-patents-from-idea-to-invention-2' seo={seo} />

      <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
        <div class="container py-5">
          <div class="text-center">
            <h1 className='text-white'> International Patent </h1>
            <h6 className='text-white'>The webinar is provided free of cost and is a collaboration between Inventiv and PatentPC</h6>
          </div>
        </div>
      </div>

      {/* <!-- Rsvp start --> */}
      <div class="rsvp content-area-2" id="rsvp">
        <div class="container px-5">
          <div className='px-5'>
            <div class="row">
              <div class="col-12 wow fadeInUp delay-04s">
                <div class="row">
                  <h2 className="text-center bold">{WEBINAR_TITLE}</h2>
                  <h5 className='text-center'>Created By Patent Lawyers, For Patent Lawyers. </h5>
                </div>
              </div>
            </div>
            <div className='my-5'>
              <div className='row'>
                <div className='col-md-12 row-flex wow fadeInUp delay-04s'>
                  <div className='div-content'>
                    <div className='circular_image'>
                      <img style={{ display: 'block', objectFit: 'cover' }} src={InternationaPatentImg} alt={WEBINAR_TITLE}></img>
                    </div>
                    <p className='text-center'>Don’t miss this webinar by our partner PatentPC</p>
                    <h5 className='bold mt-2 text-center'>Instant watch session available. Join now!</h5>

                  </div>
                  <div className='div-content'>
                    <p>Getting a patent doesn’t happen overnight. It’s a structured process that requires careful planning, documentation, and legal steps. Let’s break it down.</p>

                    <h3>Step 1: Developing a Patentable Idea</h3>
                    <p>Not every idea can be patented. For an invention to qualify, it must meet three key criteria:</p>
                    <p className='ps-5'>
                      <ul style={{ listStyle: 'disc' }}>
                        <li><strong>It must be new</strong> – Your invention should be unique and different from anything that already exists.</li>
                        <li><strong>It must be useful</strong> – The invention must serve a practical purpose.</li>
                        <li><strong>It must be non-obvious</strong> – Your idea cannot be an obvious improvement of an existing product or process.</li>
                      </ul>
                    </p>
                    <p>Before moving forward, take time to research the market and make sure your invention is truly original. Many patents get rejected because similar inventions already exist.</p>

                    <p className='text-center'>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                    </p>

                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 wow fadeInUp delay-04s'>
                  <div className='div-content mw-350'>

                    <h3>Step 2: Conducting a Patent Search</h3>
                    <p>A <strong>patent search</strong> helps you determine if someone has already patented a similar idea. This step saves time and prevents costly mistakes. Searching through existing patents may also help you refine your idea and make it even stronger.</p>
                    <p>You can perform a basic patent search through online databases like <strong>USPTO (United States Patent and Trademark Office) or WIPO (World Intellectual Property Organization)</strong>. For deeper research, consider hiring a patent attorney to ensure your invention is unique.</p>


                    <h3>Step 3: Creating a Strong Patent Application</h3>
                    <p>A patent application needs to be detailed and precise. It should describe your invention in a way that clearly explains how it works and why it’s different. Key parts of a strong patent application include:</p>
                    <p className='ps-5'>
                      <ul style={{ listStyle: 'disc' }}>
                        <li>A <strong>clear title</strong> that defines the invention</li>
                        <li>A <strong>detailed description</strong> of how it functions</li>
                        <li><strong>Diagrams or drawings</strong> to visually represent the invention</li>
                        <li><strong>Claims that define the scope of protection</strong></li>
                      </ul>
                    </p>
                    <p>Drafting a patent application requires precision. If the claims are too broad, they may be rejected. If they are too narrow, competitors might work around them. This is why many inventors work with patent professionals to ensure their application is strong and legally sound.</p>


                    <h3>Step 4: Filing the Patent Application</h3>
                    <p>Once your patent application is ready, it must be filed with the relevant patent office. The application process can take months or even years, depending on the complexity of the invention and the review process.</p>
                    <p>During this time, the patent office may ask for additional clarifications or modifications. Responding quickly and accurately to these requests increases your chances of approval.</p>

                    <h3>Step 5: Defending and Maintaining Your Patent</h3>
                    <p>Once a patent is granted, the work isn’t over. You need to actively protect it by monitoring for infringement and taking legal action if necessary. Many inventors also license their patents, allowing other businesses to use their invention in exchange for royalties.</p>

                  </div>
                  <div className='div-content mw-350'>
                    <h3>The Business Power of Patents</h3>
                    <p>A patent is not just a legal document—it’s a business tool that can help you raise investment, increase product value, and establish market dominance. Many startups use patents to secure funding, as investors see patented technology as a valuable asset with strong growth potential.</p>
                    <p>Companies like Apple, Google, and Tesla have built their success on strong patent portfolios, protecting their technology from competitors and creating long-term value. Even small businesses can leverage patents to license their technology, create partnerships, or sell their invention for profit.</p>

                  </div>


                  <div className='div-content mw-350'>
                    <h3>Common Mistakes to Avoid</h3>
                    <p>Many inventors make simple mistakes that can jeopardize their patent applications. Here are a few key pitfalls to avoid:</p>
                    <p className='ps-5'>
                      <ul style={{ listStyle: 'disc' }}>
                        <li><strong>Waiting too long to file</strong> – If you publicly disclose your invention before filing a patent, you risk losing your rights.</li>
                        <li><strong>Not conducting a thorough patent search</strong> – Assuming your idea is unique without checking existing patents can lead to rejection.</li>
                        <li><strong>Writing a vague application</strong> – A poorly written patent application can be challenged or easily bypassed by competitors.</li>
                        <li><strong>Ignoring international protection</strong> – If you plan to sell globally, consider filing for international patents to secure broader protection.</li>
                      </ul></p>
                  </div>
                  <div className='div-content mw-350'>
                    <h3>Final Thoughts: Protecting Your Ideas for the Future</h3>
                    <p>Your ideas are valuable, but without protection, they can be taken by others. Understanding the patent process gives you the power to secure your innovation, build a strong business, and create long-term success.</p>
                    <p>By the end of this webinar, you will have a clear roadmap for turning your idea into a patented invention. Whether you’re a first-time inventor or an experienced entrepreneur, taking the right steps now will set you up for success.</p>
                    <p>Let’s dive in and master the world of patents together.</p>
                  </div>

                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 offset-md-3'>
                  <p className='text-center'>
                    <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container px-5'>
        <div class="d-flex justify-content-center">
          <img src={IPProtectionImg} alt='Our lawyers have worked on IP protection for' />
        </div>
      </div>
      <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
      {/* <!-- Rsvp end --> */}
    </Layout>
  )
};

export default WebinarUnderstandingPatents;